export default {
  "homeNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "personalNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
  "businessNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "enterpriseNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise"])},
  "heroMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insure your future, today."])},
  "subHero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop looking for lenders, let lenders find you!"])},
  "buttonHero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
  "oneHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save time:"])},
  "oneHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit one form and have it delivered to over 10 Financial Institutions and banks."])},
  "twoHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easier to Qualify:"])},
  "twoHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mamun has developed alternative credit scoring mechanism to give you more access to personal and other forms of loans"])},
  "threeHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple options:"])},
  "threeHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare offers from multiple banks and financial institutions and get the lowest interest rates."])},
  "fourHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick turnaround:"])},
  "fourHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get access to your funds within 48 hours or less!"])},
  "titlePartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Access to Personal and Business Loans LET YOUR DATA WORK FOR YOU"])},
  "subPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily track and manage your application for different financial products."])},
  "personalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save time and apply online 🚀"])},
  "personalSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi placeat labore magni voluptatibus consectetur quidem, quos a earum tempora autem, nemo expedita et repellendus error corrupti totam consequuntur suscipit repudiandae."])},
  "personalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
  "personalFormSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill up the form below to send us a message."])},
  "personalFormNameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "personalFormNamePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohammed Al Jabri."])},
  "personalFormEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "personalFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "personalMessageFormPlaceholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
  "personalMessageFormPlaceholderSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
  "personalFormButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
  "businessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If time is money, effort is gold. 💰"])},
  "businessSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi placeat labore magni voluptatibus consectetur quidem, quos a earum tempora autem, nemo expedita et repellendus error corrupti totam consequuntur suscipit repudiandae."])},
  "businessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "businessFormSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill up the form below to send us a message."])},
  "businessFormNameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "businessFormNamePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohammed Al Jabri."])},
  "businessFormEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "businessFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "businessMessageFormPlaceholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
  "businessMessageFormPlaceholderSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
  "businessFormButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
  "enterpriseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are a business that has an. 💹"])},
  "enterpriseListOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP/CRM"])},
  "enterpriseListTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "enterpriseListThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SaaS"])},
  "enterpriseListFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment service provider"])},
  "enterpriseSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi placeat labore magni voluptatibus consectetur quidem, quos a earum tempora autem, nemo expedita et repellendus error corrupti totam consequuntur suscipit repudiandae."])},
  "enterpriseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "enterpriseFormSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill up the form below to send us a message."])},
  "enterpriseFormNameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "enterpriseFormNamePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohammed Al Jabri."])},
  "enterpriseFormEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "enterpriseFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "enterpriseMessageFormPlaceholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
  "enterpriseMessageFormPlaceholderSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
  "enterpriseFormButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])}
}