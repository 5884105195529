<template>
  <div class="font-cairo">
<div class="bg-mamun-primary border-b-8 border-mamun-yellowMamun">
  <div class="bg-mamun-texture02">
      <section class="flex font-cairo md:pt-40 lg:pt-40 pt-10 md:pb-20 lg:pb-20 text-white">
      <div class="m-auto">
        <div class="grid pb-10 md:pb-0 lg:pb-0 md:grid-cols-2 lg:grid-cols-2  items-center " >
          <div class="grid grid-cols-1 md:px-5 lg:px-10 text-right px-5">
            <h1 data-aos="fade-up" class="heroMainText text-4xl pb-8 font-bold">قدم طلبك أحصل على عرض مناسب و أدر طلبك</h1>
            <h1 data-aos="fade-right" class="text-xl pb-4">هل حاب تقدم طلب قرض و تحاتي الزحمة و التنقل من بنك الى أخر ؟ لا تحاتي مأمون هنا</h1>
            <div class="flex space-x-3">
              <a href="/business">
              <div data-aos="fade-right" class="text-mamun-primary transition text-center cursor-pointer w-28 duration-100 font-bold uppercase ease-in bg-mamun-yellowMamun hover:bg-white rounded py-2" >
              أطلب الآن
            </div>
            </a>
            <a href="/business">
              <div data-aos="fade-left" class=" text-mamun-primary underline hover:text-mamun-yellowMamun transition text-center cursor-pointer w-28 duration-100 font-bold uppercase ease-in rounded-md py-2" >
              جرب الخدمة
            </div>
            </a>
          </div>
            </div>
          <div class="pt-5 md:pt-0 lg:pt-0 right-0">
            <img data-aos="fade-left" src="@/assets/artboard–1.png" alt="one">
          </div>
        </div>
      </div>
    </section>
    <section>
      <div>
        <div>
          <h1 data-aos="fade-down" class="font-bold text-center text-white text-4xl uppercase">شركائنا</h1>
          <div data-aos="fade-up" class="flex flex-wrap justify-center">
            <img class="w-28 m-5 object-contain" src="@/assets/bs-logo.png" alt="">
            <img class="w-28 m-5 object-contain" src="@/assets/BM_logo.png" alt="">
            <img class="w-28 m-5 object-contain" src="@/assets/bd-logo.png" alt="">
            <img class="w-28 m-5 object-contain" src="@/assets/OCB_logo.png" alt="">
          </div>
        </div>
      </div>
    </section>
</div>
</div>
    <div class="">
      <section class="panel flex font-cairo">
      <div class="m-auto">
        <div class=" grid pb-28 md:pb-0 lg:pb-0 md:grid-cols-2 lg:grid-cols-2 lg:px-20 text-white items-center bg-gradient-to-b from-mamun-primary to-white" >
          <div id="EmbeddedCredit" class="heroSec grid grid-cols-1 px-10 pt-20">
            <div >
              <h1 data-aos="fade-left" class="heroMainText text-4xl text-mamun-yellowMamun pb-4 font-bold"> قدم طلب التمويل في غضون 5 دقائق </h1>
            </div>
            <h1 data-aos="fade-up" class="text-xl text-mamun-primary pb-4"> هل حاب تقدم طلب قرض و تحاتي الزحمة و التنقل من بنك الى أخر ؟ لا تحاتي مأمون هنا </h1>
            <div class="flex space-x-3">
              <a href="/business">
              <div data-aos="fade-right" class="text-white transition text-center cursor-pointer w-28 duration-100 font-bold uppercase ease-in bg-mamun-primary hover:bg-mamun-pink hover:text-mamun-primary rounded py-2" >
              أطلب الآن
            </div>
            </a>
          </div>
          </div>
            <div class="grid grid-cols-1 relative left-20 justify-items-center">
              <img data-aos="fade-left" src="@/assets/artboard–2.png" alt="one">
          </div>
        </div>
        <div class="grid md:grid-cols-3 lg:grid-cols-3 text-mamun-primary mb-10 pb-10 px-5">
            <div data-aos="fade-up" class="rounded p-5 mx-2 my-2">
              <img class="w-20 pb-5" src="@/assets/phone.png">
              <h1 class="text-2xl font-bold pb-2 text-mamun-primary">طريقة تقديم الطلب</h1>
              <h1 class="text-mamun-primary"> هل حاب تقدم طلب قرض و تحاتي الزحمة و التنقل من بنك الى أخر ؟ لا تحاتي مأمون هنا .</h1>
            </div>
            <div data-aos="fade-down" class="rounded p-5 mx-2 my-2">
              <img class="w-24 pb-5" src="@/assets/doc.png">
              <h1 class="text-2xl font-bold pb-2 text-mamun-primary">المستندات</h1>
              <h1 class="text-mamun-primary"> هل حاب تقدم طلب قرض و تحاتي الزحمة و التنقل من بنك الى أخر ؟ لا تحاتي مأمون هنا .</h1>
            </div>
            <div data-aos="fade-up" class=" rounded relative p-5 mx-2 my-2">
              <img class="w-20 pb-5" src="@/assets/clock.png">
              <h1 class="text-2xl pb-2 font-bold text-mamun-primary">مدة الانتظار</h1>
              <h1 class="text-mamun-primary"> هل حاب تقدم طلب قرض و تحاتي الزحمة و التنقل من بنك الى أخر ؟ لا تحاتي مأمون هنا .</h1>
            </div>
          </div>
        <div class=" grid pt-10 pb-16 md:grid-cols-2 lg:grid-cols-2 lg:px-20 text-white items-center bg-mamun-yellowMamun" >
          <div class="grid grid-cols-1 justify-items-center relative right-16">
            <img data-aos="fade-right"  src="@/assets/artboard–3.png" alt="one">
          </div>
          <div id="EmbeddedPayment" class="heroSec grid grid-cols-1 pr-10 pt-20">
            <h1 data-aos="fade-left" class="heroMainText text-4xl text-right pb-4 font-bold text-mamun-primary"> أحصل على أفضل عروض التمويل في عمان </h1>
            <h1 data-aos="fade-in" class="text-xl pb-4 text-mamun-primary text-right"> هل حاب تقدم طلب قرض و تحاتي الزحمة و التنقل من بنك الى أخر ؟ لا تحاتي مأمون هنا</h1>
                      <img data-aos="flip-left" src="@/assets/artboard–6.png" alt="">
          </div>
        </div>
        
        <div class=" grid py-16 md:grid-cols-2 lg:grid-cols-2 text-white items-center " >
          <div class="heroSec grid grid-cols-1 pl-5 pt-20">
            <h1 data-aos="fade-right" class="heroMainText text-4xl pb-4 font-bold text-mamun-primary"> وافق على التمويل اونلاين و خلص بسرعة في الفرع</h1>
            <h1 data-aos="fade-right" class="text-xl pb-4 text-mamun-primary"> هل حاب تقدم طلب قرض و تحاتي الزحمة و التنقل من بنك الى أخر ؟ لا تحاتي مأمون هنا</h1>
                        <div class="flex space-x-3">
              <a href="/business">
              <div data-aos="fade-right" class="text-white transition text-center cursor-pointer w-28 duration-100 font-bold uppercase ease-in bg-mamun-primary hover:bg-mamun-yellowMamun hover:text-mamun-primary rounded py-2" >
              أطلب الآن
            </div>
            </a>
          </div>
          </div>
          <div class="grid grid-cols-1 relative left-10">
            <img data-aos="fade-up" src="@/assets/artboard–4.png" alt="one">
          </div>
        </div>
      </div>
    </section>
    </div>
    <section data-aos="fade-up" class="font-cairo py-32 bg-mamun-yellowMamun bg-mamun-texture01 bg-no-repeat bg-auto bg-left mt-20">
        <div class="grid grid-cols-2 justify-items-center items-center px-5 md:px-20 lg:px-20 ">
<div>
            <h1 class="heroMainText font-bold text-mamun-primary uppercase"> أعمل معنا الان </h1>
          <h1 class="heroMainText md:text-4xl lg:text-4xl text-2xl pb-4 font-bold text-mamun-primary uppercase"> وسع أفاق تعاملك التجاري </h1>
</div>
            <a href="/business">
            <div class="text-white hover:text-mamun-primary transition cursor-pointer text-center duration-100 font-semibold uppercase ease-in bg-mamun-primary mt-5 hover:bg-white rounded px-4 py-2" >
              تواصل معنا
            </div>
            </a>
        </div>
    </section>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  
  name: "Home",
  components: {
    
  },
  mounted(){
  window.document.onscroll = () => {
      let navBar = document.getElementById('nav');
      if(window.scrollY > navBar.offsetTop){
        this.active = true;
        } else {
        this.active = false;
      }
    }
  },
  created() {
    AOS.init();
  },
};
</script>

<style>

/* body{
  background: linear-gradient(180deg, rgba(60,1,116,1) 5%, rgba(224,208,241,1) 50%, rgba(60,1,116,1) 100%);
} */

</style>