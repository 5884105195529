export default {
  "homeNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "personalNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشخصية"])},
  "businessNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعمال"])},
  "enterpriseNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاريع"])},
  "heroMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحصل على أفضل عروض القروض الذكية في السلطنة."])},
  "subHero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توقف عن البحث عن القروض و دعها تأتي إليك."])},
  "buttonHero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبدا الان"])},
  "oneHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالتقديم لقرض في خمس دقائق"])},
  "oneHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور."])},
  "twoHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحصل على أفضل العروض في السلطنة"])},
  "twoHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور."])},
  "threeHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإدارة قرضك أونلاين"])},
  "threeHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور."])},
  "fourHowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple options: Compare offers from multiple banks and financial institutions and get the lowest interest rates."])},
  "fourHowSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick turnaround: Get access to your funds within 48 hours or less!"])},
  "titlePartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحصل على بيانات القروض الشخصية و للمشاريع"])},
  "subPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سهولة الحصول على جميع المعلومات عن قرضك عن طريق منصة واحدة."])},
  "personalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستغل الوقت و قم بالتقديم أونلاين 🚀"])},
  "personalSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور.لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور.لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور."])},
  "personalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشخصي"])},
  "personalFormSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أملاء جميع الخانات لأرسال رسالة."])},
  "personalFormNameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم كامل"])},
  "personalFormNamePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محمد الجابري"])},
  "personalFormEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "personalFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "personalMessageFormPlaceholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "personalMessageFormPlaceholderSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "personalFormButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "businessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كان الوقت من مال فالعمل من ذهب 💰"])},
  "businessSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور.لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور.لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور."])},
  "businessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعمال"])},
  "businessFormSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أملاء جميع الخانات لأرسال رسالة."])},
  "businessFormNameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم كامل"])},
  "businessFormNamePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محمد الجابري"])},
  "businessFormEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "businessFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "businessMessageFormPlaceholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "businessMessageFormPlaceholderSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "businessFormButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "enterpriseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت ترغب في بداية مشروعك في هنا تبدأ 💹"])},
  "enterpriseListOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحات تحكم"])},
  "enterpriseListTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاجر"])},
  "enterpriseListThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرمجيات كخدمة"])},
  "enterpriseListFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوابة دفع إلكترونية"])},
  "enterpriseSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور.لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور.لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور."])},
  "enterpriseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعمال"])},
  "enterpriseFormSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أملاء جميع الخانات لأرسال رسالة."])},
  "enterpriseFormNameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم كامل"])},
  "enterpriseFormNamePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محمد الجابري"])},
  "enterpriseFormEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "enterpriseFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "enterpriseMessageFormPlaceholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "enterpriseMessageFormPlaceholderSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "enterpriseFormButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])}
}