<template>
  <section
    class="md:flex lg:flex font-cairo md:h-screen lg:h-screen py-10"
  >
    <div class="m-auto">
      <div
        class="grid md:grid-cols-1 lg:grid-cols-2 px-5 text-white items-center"
      >
        <div class="md:px-10 lg:px-10">
          <h1 data-aos="fade-right" class="text-4xl pb-4 font-bold">
           {{ $t('enterpriseTitle') }}
          </h1>
          <h1>
            {{ $t('enterpriseSub') }}
          </h1>
        </div>
        <div>
          <div class="container mx-auto">
            <div
              class="max-w-md mx-auto my-10 bg-white text-mamun-primary p-5 rounded-md shadow-sm"
            >
              <div class="text-center">
                <h1
                  class="
                    my-3
                    text-3xl
                    font-semibold
                    text-gray-700
                    dark:text-gray-200
                  "
                >
                  {{ $t('enterpriseName') }}
                </h1>
                <p class="text-gray-400 font-cairo dark:text-gray-400">
                  {{ $t('enterpriseFormSub') }}
                </p>
              </div>
              <div class="m-7">
                <form
                  action="https://api.web3forms.com/submit"
                  method="POST"
                  id="form"
                >
                  <input
                    type="hidden"
                    name="apikey"
                    value="YOUR_ACCESS_KEY_HERE"
                  />
                  <input
                    type="hidden"
                    name="subject"
                    value="New Submission from Web3Forms"
                  />
                  <input
                    type="checkbox"
                    name="botcheck"
                    id=""
                    style="display: none"
                  />

                  <div class="mb-6">
                    <label
                      for="name"
                      class="
                        block
                        mb-2
                        text-sm text-gray-600
                        dark:text-gray-400
                      "
                      >{{ $t('enterpriseFormNameTitle') }}</label
                    >
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="John Doe"
                      required
                      class="
                        w-full
                        px-3
                        py-2
                        placeholder-gray-300
                        border border-gray-300
                        rounded-md
                        focus:outline-none
                        focus:ring
                        focus:ring-indigo-100
                        focus:border-indigo-300
                        dark:bg-gray-700
                        dark:text-white
                        dark:placeholder-gray-500
                        dark:border-gray-600
                        dark:focus:ring-gray-900
                        dark:focus:border-gray-500
                      "
                    />
                  </div>
                  <div class="mb-6">
                    <label
                      for="email"
                      class="
                        block
                        mb-2
                        text-sm text-gray-600
                        dark:text-gray-400
                      "
                      >{{ $t('enterpriseFormEmailTitle') }}</label
                    >
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="you@company.com"
                      required
                      class="
                        w-full
                        px-3
                        py-2
                        placeholder-gray-300
                        border border-gray-300
                        rounded-md
                        focus:outline-none
                        focus:ring
                        focus:ring-indigo-100
                        focus:border-indigo-300
                        dark:bg-gray-700
                        dark:text-white
                        dark:placeholder-gray-500
                        dark:border-gray-600
                        dark:focus:ring-gray-900
                        dark:focus:border-gray-500
                      "
                    />
                  </div>
                  <div class="mb-6">
                    <label
                      for="phone"
                      class="text-sm text-gray-600 dark:text-gray-400"
                      >{{ $t('enterpriseFormTitle') }}</label
                    >
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="+1 (555) 1234-567"
                      required
                      class="
                        w-full
                        px-3
                        py-2
                        placeholder-gray-300
                        border border-gray-300
                        rounded-md
                        focus:outline-none
                        focus:ring
                        focus:ring-indigo-100
                        focus:border-indigo-300
                        dark:bg-gray-700
                        dark:text-white
                        dark:placeholder-gray-500
                        dark:border-gray-600
                        dark:focus:ring-gray-900
                        dark:focus:border-gray-500
                      "
                    />
                  </div>
                  <div class="mb-6">
                    <label
                      for="message"
                      class="
                        block
                        mb-2
                        text-sm text-gray-600
                        dark:text-gray-400
                      "
                      >{{ $t('enterpriseMessageFormPlaceholderTitle') }}</label
                    >

                    <textarea
                      rows="5"
                      name="message"
                      id="message"
                      placeholder="Your Message"
                      class="
                        w-full
                        px-3
                        py-2
                        placeholder-gray-300
                        border border-gray-300
                        rounded-md
                        focus:outline-none
                        focus:ring
                        focus:ring-indigo-100
                        focus:border-indigo-300
                        dark:bg-gray-700
                        dark:text-white
                        dark:placeholder-gray-500
                        dark:border-gray-600
                        dark:focus:ring-gray-900
                        dark:focus:border-gray-500
                      "
                      required
                    ></textarea>
                  </div>
                  <div class="mb-6">
                    <button
                      type="submit"
                      class="
                        w-full
                        px-3
                        py-4
                        text-whit
                        bg-mamun-primary
                        hover:bg-mamun-primary2
                        rounded-md
                        text-white
                        focus:outline-none
                      "
                    >
                      {{$t('enterpriseFormButton')}}
                    </button>
                  </div>
                  <p
                    class="text-base text-center text-gray-400"
                    id="result"
                  ></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "enterprise",
};
</script>
