<template>
  <div id="header" class="flex justify-between items-center px-5 py-5 bg-mamun-primary shadow-xl">
    <router-link to="/" ><img class="h-10" alt="Vue logo" src="@/assets/Mamun-Logo.png" /></router-link>
    <div
      class=" hidden md:flex lg:flex justify-center justify-items-center space-x-5 px-5 text-white font-cairo " >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" >الرئيسية</router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" >تقديم الطلب </router-link>
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" >المشاريع</router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" >البلوج</router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" >إتصل بنا</router-link >

      <!-- <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" >{{ $t("homeNav") }}</router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/personal" >{{ $t("personalNav") }} </router-link>
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/business" >{{ $t("businessNav") }}</router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/enterprise" >{{ $t("enterpriseNav") }}</router-link > -->

      <!-- <router-link class="hover:text-mamun-secondary transition duration-100 ease-in relative hover-trigger" to="/" >Products
      <div class="absolute py-2 hover-target bg-mamun-primary2 w-48 rounded px-4">
        <a v-scroll-to="{ el: '#EmbeddedCredit', offset: -200 }"><h1 class="text-white hover:text-mamun-secondary py-2 border-b border-mamun-primary">Loans & Financing marketplace</h1></a>
        <a v-scroll-to="{ el: '#EmbeddedCredit', offset: -200 }"><h1 class="text-white hover:text-mamun-secondary py-2 border-b border-mamun-primary">Buy-Now-Pay-Later</h1></a>
        <a v-scroll-to="{ el: '#EmbeddedCredit', offset: -200 }"><h1 class="text-white hover:text-mamun-secondary py-2 border-b border-mamun-primary">Tokenized Debit Cards</h1></a>
        <a v-scroll-to="{ el: '#EmbeddedPayment', offset: -200 }"><h1 class="text-white hover:text-mamun-secondary py-2 border-b border-mamun-primary">eDirect Debit</h1></a>
        <a v-scroll-to="{ el: '#EmbeddedInsurance', offset: -200 }"><h1 class="text-white hover:text-mamun-secondary py-2 border-mamun-primary">Insurance</h1></a>
      </div>
      </router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" >Enterprise Types</router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in relative hover-trigger" to="/" >For Financial Institutions
      <div class="absolute py-2 hover-target bg-mamun-primary2 w-48 rounded px-4">
        <h1 class="text-white hover:text-mamun-secondary py-2 border-b border-mamun-primary">Alternative Credit Scoring</h1>
        <h1 class="text-white hover:text-mamun-secondary py-2 border-mamun-primary">OpenBanking</h1>
      </div>
      </router-link>
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" ><a v-scroll-to="{ el: '#IntegrationMode', offset: -200 }">Integration mode</a></router-link >
      <router-link class="hover:text-mamun-secondary transition duration-100 ease-in" to="/enterprise" >Contact us</router-link >
      <div>
        
           <select class="bg-mamun-primary font-cairo focus:outline-none" v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang.value${i}`" :value="lang.value">
        {{ lang.lable }}</option>
    </select>

      </div> -->
    </div>
    
    <div class="md:hidden lg:hidden flex justify-center justify-items-center font-cairo space-x-2">

      <!-- <select class="bg-mamun-primary text-white fill-current focus:outline-none" v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang.value${i}`" :value="lang.value">
        {{ lang.lable }}</option>
    </select> -->

      <button @click="showNav = !showNav">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
                  
    </div>
  </div>
  <router-view />
  <transition name="fade">
    <div v-if="showNav" class=" font-cairo md:hidden w-full lg:hidden text-white absolute right-0 top-20 text-right " >
      <router-link @click="showNav = !showNav" class="hover:text-mamun-secondary" to="/" ><div class=" bg-mamun-primary2 pr-5 border-b border-mamun-primary hover:bg-mamun-primary py-2 transition duration-100 ease-in " > الرئيسية </div></router-link >
      <router-link @click="showNav = !showNav" class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" ><div class=" bg-mamun-primary2 pr-5 border-b border-mamun-primary hover:bg-mamun-primary py-2 transition duration-100 ease-in " > تقديم الطلب </div></router-link >
      <router-link @click="showNav = !showNav" class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" ><div class=" bg-mamun-primary2 pr-5 border-b border-mamun-primary hover:bg-mamun-primary py-2 transition duration-100 ease-in " > المشاريع </div></router-link >
      <router-link @click="showNav = !showNav" class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" ><div class=" bg-mamun-primary2 pr-5 border-b border-mamun-primary hover:bg-mamun-primary py-2 transition duration-100 ease-in " > البلوج </div></router-link >
      <router-link @click="showNav = !showNav" class="hover:text-mamun-secondary transition duration-100 ease-in" to="/" ><div class=" bg-mamun-primary2 pr-5 border-b border-mamun-primary hover:bg-mamun-primary py-2 transition duration-100 ease-in " > اتصل بنا </div></router-link >

    </div></transition >
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      showNav: false,
      langs: [
        {value:'ar', lable: 'العربية'},
        {value:'en', lable:'English'},
        ],
    };
  },

  }
</script>

<style>
#header {
    position:fixed; /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
  left:0;           /* top left corner should start at leftmost spot */
  top:0;            /* top left corner should start at topmost spot */
  width:100vw;      /* take up the full browser width */
  z-index:200;  /* high z index so other content scrolls underneath */ 
}
.hover-trigger .hover-target {
    display: none;
}

.hover-trigger:hover .hover-target {
    display: block;
}
</style>