import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Personal from "../views/Personal.vue";
import Business from "../views/Business.vue";
import Enterprise from "../views/Enterprise.vue";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: 'Home'
        },
    },
    {
        path: "/personal",
        name: "Personal",
        component: Personal,
        meta: {
            title: 'Personal'
        },
    },
    {
        path: "/business",
        name: "Business",
        component: Business,
        meta: {
            title: 'Business'
        },
    },
    {
        path: "/enterprise",
        name: "Enterprise",
        component: Enterprise,
        meta: {
            title: 'Enterprise'
        },
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = `Mamun | ${to.meta.title}`;
    next();
})

export default router;